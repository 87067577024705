import React, { useMemo } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Button } from '@material-ui/core';
import Navigation from '../../components/Navigation';

const SideBarContent = ({ userType }) => {
  const navigationMenus = useMemo(
    () =>
      userType === 'admin'
        ? [
            {
              name: 'pages.dashboard',
              type: 'item',
              link: '/app/dashboard',
            },
            {
              name: 'pages.masters',
              type: 'collapse',
              children: [
                { name: 'masters.bloodGroup', type: 'item', link: '/app/masters/blood-group' },
                { name: 'masters.caste', type: 'item', link: '/app/masters/caste' },
                { name: 'masters.college', type: 'item', link: '/app/masters/college' },
                { name: 'masters.company', type: 'item', link: '/app/masters/company' },
                { name: 'masters.complexion', type: 'item', link: '/app/masters/complexion' },
                { name: 'masters.designation', type: 'item', link: '/app/masters/designation' },
                { name: 'masters.education', type: 'item', link: '/app/masters/education' },
                {
                  name: 'masters.employmentStatus',
                  type: 'item',
                  link: '/app/masters/employment-status',
                },
                { name: 'masters.eventType', type: 'item', link: '/app/masters/event-type' },
                {
                  name: 'masters.familyAffluence',
                  type: 'item',
                  link: '/app/masters/family-affluence',
                },
                { name: 'masters.familyType', type: 'item', link: '/app/masters/family-type' },
                { name: 'masters.familyValue', type: 'item', link: '/app/masters/family-value' },
                {
                  name: 'masters.favoriteSports',
                  type: 'item',
                  link: '/app/masters/favorite-sports',
                },
                {
                  name: 'masters.favoriteMusic',
                  type: 'item',
                  link: '/app/masters/favorite-music',
                },
                { name: 'masters.foodHabits', type: 'item', link: '/app/masters/food-habits' },
                { name: 'masters.gender', type: 'item', link: '/app/masters/gender' },
                { name: 'masters.healthInfo', type: 'item', link: '/app/masters/health-info' },
                { name: 'masters.identityType', type: 'item', link: '/app/masters/indentity-type' },
                {
                  name: 'masters.maritalStatus',
                  type: 'item',
                  link: '/app/masters/marital-status',
                },
                {
                  name: 'masters.medicalHistory',
                  type: 'item',
                  link: '/app/masters/medical-history',
                },
                { name: 'masters.nativePlace', type: 'item', link: '/app/masters/native-place' },
                { name: 'masters.occupation', type: 'item', link: '/app/masters/occupation' },
                {
                  name: 'masters.professionalArea',
                  type: 'item',
                  link: '/app/masters/professional-area',
                },
                { name: 'masters.tobacco', type: 'item', link: '/app/masters/tobacco' },
                { name: 'masters.country', type: 'item', link: '/app/masters/country' },
                { name: 'masters.state', type: 'item', link: '/app/masters/state' },
                { name: 'masters.city', type: 'item', link: '/app/masters/city' },
                { name: 'masters.zip', type: 'item', link: '/app/masters/zip' },
                {
                  name: 'masters.religion',
                  type: 'item',
                  link: '/app/masters/religion',
                },
                {
                  name: 'masters.community',
                  type: 'item',
                  link: '/app/masters/community',
                },
                {
                  name: 'masters.subCommunity',
                  type: 'item',
                  link: '/app/masters/sub-community',
                },
                {
                  name: 'masters.languages',
                  type: 'item',
                  link: '/app/masters/languages',
                },
                {
                  name: 'masters.relation',
                  type: 'item',
                  link: '/app/masters/relation',
                },
                {
                  name: 'masters.lifestyleOptions',
                  type: 'item',
                  link: '/app/masters/lifestyle-options',
                },
                {
                  name: 'masters.drinking',
                  type: 'item',
                  link: '/app/masters/drinking',
                },
                {
                  name: 'masters.smoking',
                  type: 'item',
                  link: '/app/masters/smoking',
                },
                {
                  name: 'masters.interest',
                  type: 'item',
                  link: '/app/masters/interest',
                },
                { name: 'masters.hobbies', type: 'item', link: '/app/masters/hobbies' },
                { name: 'masters.gotra', type: 'item', link: '/app/masters/gotra' },
                {
                  name: 'masters.donationPurpose',
                  type: 'item',
                  link: '/app/masters/donation-purpose',
                },
                {
                  name: 'masters.reportReason',
                  type: 'item',
                  link: '/app/masters/report-reason',
                },
              ],
            },
            {
              name: 'pages.matrimony',
              type: 'collapse',
              children: [
                {
                  name: 'matrimony.users',
                  type: 'collapse',
                  children: [
                    {
                      name: 'users.allPendingVerification',
                      type: 'item',
                      link: '/app/matrimony/all-pending-verification',
                    },
                    {
                      name: 'users.bannedUser',
                      type: 'item',
                      link: '/app/matrimony/banned-user',
                    },
                    {
                      name: 'users.deactivatedUser',
                      type: 'item',
                      link: '/app/matrimony/deactivated-user',
                    },
                    {
                      name: 'users.allUsers',
                      type: 'item',
                      link: '/app/matrimony/all-users',
                    },
                    {
                      name: 'users.complainedUsers',
                      type: 'item',
                      link: '/app/matrimony/complained-users',
                    },
                  ],
                },
                {
                  name: 'matrimony.subscription',
                  type: 'collapse',
                  children: [
                    {
                      name: 'subscription.products',
                      type: 'item',
                      link: '/app/matrimony/products',
                    },
                    {
                      name: 'subscription.coupons',
                      type: 'item',
                      link: '/app/matrimony/coupons',
                    },
                    {
                      name: 'subscription.allSubscriptions',
                      type: 'item',
                      link: '/app/matrimony/all-subscription',
                    },
                    {
                      name: 'subscription.cancelSubscription',
                      type: 'item',
                      link: '/app/matrimony/cancel-subscription',
                    },
                  ],
                },
                {
                  name: 'matrimony.revenue',
                  type: 'item',
                  link: '/app/matrimony/revenue',
                },
              ],
            },
            {
              name: 'pages.community',
              type: 'collapse',
              children: [
                {
                  name: 'community.users',
                  type: 'collapse',
                  children: [
                    {
                      name: 'users.allPendingVerification',
                      type: 'item',
                      link: '/app/community/all-pending-verification',
                    },
                    {
                      name: 'users.bannedUser',
                      type: 'item',
                      link: '/app/community/banned-user',
                    },
                    {
                      name: 'users.deactivatedUser',
                      type: 'item',
                      link: '/app/community/deactivated-user',
                    },
                    {
                      name: 'users.allUsers',
                      type: 'item',
                      link: '/app/community/all-users',
                    },
                    {
                      name: 'users.reportedUsers',
                      type: 'item',
                      link: '/app/community/reported-users',
                    },
                  ],
                },
                {
                  name: 'community.subscription',
                  type: 'collapse',
                  children: [
                    {
                      name: 'subscription.products',
                      type: 'item',
                      link: '/app/community/products',
                    },
                    {
                      name: 'subscription.coupons',
                      type: 'item',
                      link: '/app/community/coupons',
                    },
                    {
                      name: 'subscription.allSubscriptions',
                      type: 'item',
                      link: '/app/community/all-subscription',
                    },
                    {
                      name: 'subscription.cancelSubscription',
                      type: 'item',
                      link: '/app/community/cancel-subscription',
                    },
                  ],
                },
                {
                  name: 'community.revenue',
                  type: 'item',
                  link: '/app/community/revenue',
                },
                {
                  name: 'community.adManagement',
                  type: 'item',
                  link: '/app/community/ad-management',
                },
                {
                  name: 'community.reportedPosts',
                  type: 'item',
                  link: '/app/community/reported-posts',
                },
                {
                  name: 'community.feeds',
                  type: 'item',
                  link: '/app/community/feeds',
                },
                {
                  name: 'community.events',
                  type: 'item',
                  link: '/app/community/events',
                },
              ],
            },
            {
              name: 'pages.cms',
              type: 'collapse',
              children: [
                {
                  name: 'cms.successStories',
                  type: 'item',
                  link: '/app/success-stories',
                },
                { name: 'cms.jobs', type: 'item', link: '/app/jobs' },
                {
                  name: 'cms.faq',
                  type: 'collapse',
                  children: [
                    {
                      name: 'pages.matrimony',
                      type: 'item',
                      link: '/app/matrimony/faq',
                    },
                    {
                      name: 'pages.community',
                      type: 'item',
                      link: '/app/community/faq',
                    },
                  ],
                },
                { name: 'cms.termsCond', type: 'item', link: '/app/terms-condition' },
                {
                  name: 'cms.htu',
                  type: 'collapse',
                  children: [
                    {
                      name: 'pages.matrimony',
                      type: 'item',
                      link: '/app/matrimony/how-to-use',
                    },
                    {
                      name: 'pages.community',
                      type: 'item',
                      link: '/app/community/how-to-use',
                    },
                  ],
                },
                {
                  name: 'cms.privPolicy',
                  type: 'item',
                  link: '/app/privacy-policy',
                },
                {
                  name: 'cms.about',
                  type: 'collapse',
                  children: [
                    {
                      name: 'pages.matrimony',
                      type: 'item',
                      link: '/app/matrimony/about',
                    },
                    {
                      name: 'pages.community',
                      type: 'item',
                      link: '/app/community/about',
                    },
                  ],
                },
                {
                  name: 'cms.disclaimer',
                  type: 'item',
                  link: '/app/disclaimer',
                },
                {
                  name: 'cms.moreTabs',
                  type: 'item',
                  link: '/app/more-tabs',
                },
                {
                  name: 'cms.visionMission',
                  type: 'collapse',
                  children: [
                    {
                      name: 'pages.matrimony',
                      type: 'item',
                      link: '/app/matrimony/vision-mission',
                    },
                    {
                      name: 'pages.community',
                      type: 'item',
                      link: '/app/community/vision-mission',
                    },
                  ],
                },
                {
                  name: 'cms.socialLink',
                  type: 'collapse',
                  children: [
                    {
                      name: 'pages.matrimony',
                      type: 'item',
                      link: '/app/matrimony/social-link',
                    },
                    {
                      name: 'pages.community',
                      type: 'item',
                      link: '/app/community/social-link',
                    },
                  ],
                },
              ],
            },

            {
              name: 'pages.donation',
              type: 'item',
              link: '/app/donation',
            },
            { name: 'pages.staff', type: 'item', link: '/app/staff' },
            {
              name: 'pages.complaint',
              type: 'item',
              link: '/app/complaint',
            },
            {
              name: 'pages.contact',
              type: 'item',
              link: '/app/contact',
            },
            {
              name: 'pages.jobApplication',
              type: 'item',
              link: '/app/job-application',
            },
            {
              name: 'pages.platformSettings',
              type: 'item',
              link: '/app/platform-settings',
            },
            {
              name: 'pages.platformAudit',
              type: 'item',
              link: '/app/platform-audit',
            },
            {
              name: 'pages.notificationCenter',
              type: 'item',
              link: '/app/notification-center',
            },
          ]
        : userType === 'verifier'
        ? [
            {
              name: 'pages.dashboard',
              type: 'item',
              link: '/app/dashboard',
            },
            {
              name: 'pages.matrimony',
              type: 'collapse',
              children: [
                {
                  name: 'matrimony.users',
                  type: 'collapse',
                  children: [
                    {
                      name: 'users.myVerifications',
                      type: 'item',
                      link: '/app/matrimony/my-verification',
                    },
                  ],
                },
              ],
            },
            {
              name: 'pages.community',
              type: 'collapse',
              children: [
                {
                  name: 'community.users',
                  type: 'collapse',
                  children: [
                    {
                      name: 'users.myVerifications',
                      type: 'item',
                      link: '/app/community/my-verification',
                    },
                  ],
                },
              ],
            },
          ]
        : [
            {
              name: 'pages.dashboard',
              type: 'item',
              link: '/app/dashboard',
            },
            {
              name: 'pages.masters',
              type: 'collapse',
              children: [
                { name: 'masters.bloodGroup', type: 'item', link: '/app/masters/blood-group' },
                { name: 'masters.caste', type: 'item', link: '/app/masters/caste' },
                { name: 'masters.college', type: 'item', link: '/app/masters/college' },
                { name: 'masters.company', type: 'item', link: '/app/masters/company' },
                { name: 'masters.complexion', type: 'item', link: '/app/masters/complexion' },
                { name: 'masters.designation', type: 'item', link: '/app/masters/designation' },
                { name: 'masters.education', type: 'item', link: '/app/masters/education' },
                {
                  name: 'masters.employmentStatus',
                  type: 'item',
                  link: '/app/masters/employment-status',
                },
                { name: 'masters.eventType', type: 'item', link: '/app/masters/event-type' },
                {
                  name: 'masters.familyAffluence',
                  type: 'item',
                  link: '/app/masters/family-affluence',
                },
                { name: 'masters.familyType', type: 'item', link: '/app/masters/family-type' },
                { name: 'masters.familyValue', type: 'item', link: '/app/masters/family-value' },
                {
                  name: 'masters.favoriteSports',
                  type: 'item',
                  link: '/app/masters/favorite-sports',
                },
                {
                  name: 'masters.favoriteMusic',
                  type: 'item',
                  link: '/app/masters/favorite-music',
                },
                { name: 'masters.foodHabits', type: 'item', link: '/app/masters/food-habits' },
                { name: 'masters.gender', type: 'item', link: '/app/masters/gender' },
                { name: 'masters.healthInfo', type: 'item', link: '/app/masters/health-info' },
                { name: 'masters.identityType', type: 'item', link: '/app/masters/indentity-type' },
                {
                  name: 'masters.maritalStatus',
                  type: 'item',
                  link: '/app/masters/marital-status',
                },
                {
                  name: 'masters.medicalHistory',
                  type: 'item',
                  link: '/app/masters/medical-history',
                },
                { name: 'masters.nativePlace', type: 'item', link: '/app/masters/native-place' },
                { name: 'masters.occupation', type: 'item', link: '/app/masters/occupation' },
                {
                  name: 'masters.professionalArea',
                  type: 'item',
                  link: '/app/masters/professional-area',
                },
                { name: 'masters.tobacco', type: 'item', link: '/app/masters/tobacco' },
                { name: 'masters.country', type: 'item', link: '/app/masters/country' },
                { name: 'masters.state', type: 'item', link: '/app/masters/state' },
                { name: 'masters.city', type: 'item', link: '/app/masters/city' },
                { name: 'masters.zip', type: 'item', link: '/app/masters/zip' },
                {
                  name: 'masters.religion',
                  type: 'item',
                  link: '/app/masters/religion',
                },
                {
                  name: 'masters.community',
                  type: 'item',
                  link: '/app/masters/community',
                },
                {
                  name: 'masters.subCommunity',
                  type: 'item',
                  link: '/app/masters/sub-community',
                },
                {
                  name: 'masters.languages',
                  type: 'item',
                  link: '/app/masters/languages',
                },
                {
                  name: 'masters.relation',
                  type: 'item',
                  link: '/app/masters/relation',
                },
                {
                  name: 'masters.lifestyleOptions',
                  type: 'item',
                  link: '/app/masters/lifestyle-options',
                },
                {
                  name: 'masters.drinking',
                  type: 'item',
                  link: '/app/masters/drinking',
                },
                {
                  name: 'masters.smoking',
                  type: 'item',
                  link: '/app/masters/smoking',
                },
                {
                  name: 'masters.interest',
                  type: 'item',
                  link: '/app/masters/interest',
                },
                { name: 'masters.hobbies', type: 'item', link: '/app/masters/hobbies' },
                { name: 'masters.gotra', type: 'item', link: '/app/masters/gotra' },
                {
                  name: 'masters.donationPurpose',
                  type: 'item',
                  link: '/app/masters/donation-purpose',
                },
              ],
            },
            {
              name: 'pages.matrimony',
              type: 'collapse',
              children: [
                {
                  name: 'matrimony.users',
                  type: 'collapse',
                  children: [
                    {
                      name: 'users.allPendingVerification',
                      type: 'item',
                      link: '/app/matrimony/all-pending-verification',
                    },
                    {
                      name: 'users.bannedUser',
                      type: 'item',
                      link: '/app/matrimony/banned-user',
                    },
                    {
                      name: 'users.allUsers',
                      type: 'item',
                      link: '/app/matrimony/all-users',
                    },
                    {
                      name: 'users.complainedUsers',
                      type: 'item',
                      link: '/app/matrimony/complained-users',
                    },
                  ],
                },
                {
                  name: 'matrimony.subscription',
                  type: 'collapse',
                  children: [
                    {
                      name: 'subscription.products',
                      type: 'item',
                      link: '/app/matrimony/products',
                    },
                    {
                      name: 'subscription.coupons',
                      type: 'item',
                      link: '/app/matrimony/coupons',
                    },
                    {
                      name: 'subscription.allSubscriptions',
                      type: 'item',
                      link: '/app/matrimony/all-subscription',
                    },
                    {
                      name: 'subscription.cancelSubscription',
                      type: 'item',
                      link: '/app/matrimony/cancel-subscription',
                    },
                  ],
                },
              ],
            },
            {
              name: 'pages.community',
              type: 'collapse',
              children: [
                {
                  name: 'community.users',
                  type: 'collapse',
                  children: [
                    {
                      name: 'users.allPendingVerification',
                      type: 'item',
                      link: '/app/community/all-pending-verification',
                    },
                    {
                      name: 'users.bannedUser',
                      type: 'item',
                      link: '/app/community/banned-user',
                    },
                    {
                      name: 'users.allUsers',
                      type: 'item',
                      link: '/app/community/all-users',
                    },
                    {
                      name: 'users.reportedUsers',
                      type: 'item',
                      link: '/app/community/reported-users',
                    },
                  ],
                },
                {
                  name: 'community.subscription',
                  type: 'collapse',
                  children: [
                    {
                      name: 'subscription.products',
                      type: 'item',
                      link: '/app/community/products',
                    },
                    {
                      name: 'subscription.coupons',
                      type: 'item',
                      link: '/app/community/coupons',
                    },
                    {
                      name: 'subscription.allSubscriptions',
                      type: 'item',
                      link: '/app/community/all-subscription',
                    },
                    {
                      name: 'subscription.cancelSubscription',
                      type: 'item',
                      link: '/app/community/cancel-subscription',
                    },
                  ],
                },
                {
                  name: 'community.adManagement',
                  type: 'item',
                  link: '/app/community/ad-management',
                },
                {
                  name: 'community.reportedPosts',
                  type: 'item',
                  link: '/app/community/reported-posts',
                },
              ],
            },
            {
              name: 'pages.cms',
              type: 'collapse',
              children: [
                {
                  name: 'cms.successStories',
                  type: 'item',
                  link: '/app/success-stories',
                },
                { name: 'cms.jobs', type: 'item', link: '/app/jobs' },
                {
                  name: 'cms.faq',
                  type: 'collapse',
                  children: [
                    {
                      name: 'pages.matrimony',
                      type: 'item',
                      link: '/app/matrimony/faq',
                    },
                    {
                      name: 'pages.community',
                      type: 'item',
                      link: '/app/community/faq',
                    },
                  ],
                },
                { name: 'cms.termsCond', type: 'item', link: '/app/terms-condition' },
                {
                  name: 'cms.htu',
                  type: 'collapse',
                  children: [
                    {
                      name: 'pages.matrimony',
                      type: 'item',
                      link: '/app/matrimony/how-to-use',
                    },
                    {
                      name: 'pages.community',
                      type: 'item',
                      link: '/app/community/how-to-use',
                    },
                  ],
                },
                {
                  name: 'cms.privPolicy',
                  type: 'item',
                  link: '/app/privacy-policy',
                },
                {
                  name: 'cms.about',
                  type: 'collapse',
                  children: [
                    {
                      name: 'pages.matrimony',
                      type: 'item',
                      link: '/app/matrimony/about',
                    },
                    {
                      name: 'pages.community',
                      type: 'item',
                      link: '/app/community/about',
                    },
                  ],
                },
                {
                  name: 'cms.disclaimer',
                  type: 'item',
                  link: '/app/disclaimer',
                },
                {
                  name: 'cms.visionMission',
                  type: 'collapse',
                  children: [
                    {
                      name: 'pages.matrimony',
                      type: 'item',
                      link: '/app/matrimony/vision-mission',
                    },
                    {
                      name: 'pages.community',
                      type: 'item',
                      link: '/app/community/vision-mission',
                    },
                  ],
                },
                {
                  name: 'cms.moreTabs',
                  type: 'item',
                  link: '/app/more-tabs',
                },
                {
                  name: 'cms.socialLink',
                  type: 'collapse',
                  children: [
                    {
                      name: 'pages.matrimony',
                      type: 'item',
                      link: '/app/matrimony/social-link',
                    },
                    {
                      name: 'pages.community',
                      type: 'item',
                      link: '/app/community/social-link',
                    },
                  ],
                },
              ],
            },

            {
              name: 'pages.donation',
              type: 'item',
              link: '/app/donation',
            },
            { name: 'pages.staff', type: 'item', link: '/app/staff' },
            {
              name: 'pages.complaint',
              type: 'item',
              link: '/app/complaint',
            },
            {
              name: 'pages.contact',
              type: 'item',
              link: '/app/contact',
            },
            {
              name: 'pages.jobApplication',
              type: 'item',
              link: '/app/job-application',
            },
            {
              name: 'pages.platformSettings',
              type: 'item',
              link: '/app/platform-settings',
            },
            {
              name: 'pages.platformAudit',
              type: 'item',
              link: '/app/platform-audit',
            },
            {
              name: 'pages.notificationCenter',
              type: 'item',
              link: '/app/notification-center',
            },
          ],
    [userType],
  );

  // sorting menu items in alphabetical order
  for (let i = 0; i < navigationMenus.length; i++) {
    if (navigationMenus[i].children) {
      navigationMenus[i].children = navigationMenus[i].children.sort((a, b) => {
        const t1 = a.name.split('.')[1];
        const t2 = b.name.split('.')[1];
        return t1 < t2 ? -1 : 1;
      });
    }
  }

  const source = window.localStorage.getItem('source');

  return (
    <CustomScrollbars className="scrollbar">
      <Navigation menuItems={navigationMenus} />
      {source === 'app' ? (
        <div className="sidebar-bottom">
          <Button
            color="secondary"
            variant="text"
            size="small"
            onClick={() => window.ReactNativeWebView?.postMessage('exit_app')}
            endIcon={<ExitToAppIcon fontSize="medium" color="secondary" />}
          >
            Exit
          </Button>
        </div>
      ) : null}
    </CustomScrollbars>
  );
};

export default SideBarContent;
