import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';

const UserInfo = () => {
  const user = useSelector(({ auth }) => auth.authUser);
  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar
        alt="..."
        src={
          user?.profileImage?.fileAbsolutePath ||
          `https://ui-avatars.com/api/?name=${user?.firstName || 'User'} ${user?.lastName || ''}}`
        }
        className="user-avatar "
      />
      <div className="user-detail">
        <h4 className="user-name d-flex">
          <Link to="/app/profile" className="text-decoration-none text-white">
            <span className="text-truncate">
              {user?.firstName || ''} {user?.lastName || ''}
            </span>
          </Link>
        </h4>
      </div>
    </div>
  );
};

export default UserInfo;
