import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import CardHeader from 'components/dashboard/Common/CardHeader/index';
import IntlMessages from 'util/IntlMessages';
import logo from 'assets/images/logo.svg';
import { toggleCollapsedNav } from 'reducers/Settings';
import AppNotification from '../../../../components/AppNotification';
import { USER_TYPE } from '../../../../store/config';

const Index = () => {
  const dispatch = useDispatch();
  const { drawerType, navCollapsed } = useSelector(({ settings }) => settings);

  const logout = () => {
    localStorage.clear('token');
    localStorage.clear('user');
    localStorage.clear(USER_TYPE);
    window.location.replace('/signin');
  };

  const onToggleCollapsedNav = () => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-block d-xl-none'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? 'd-block'
    : 'd-none';

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle}`}
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>

        <Link className="app-logo mr-2 d-none d-sm-block" to="/">
          <img src={logo} alt="Jambo" title="Jambo" />
        </Link>

        <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item app-tour">
            <Dropdown className="quick-menu" onClick={logout}>
              <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                <IconButton className="icon-btn">
                  <i className="zmdi zmdi-power mr-2" />
                  <IntlMessages id="popup.logout" />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <CardHeader
                  styleName="align-items-center"
                  heading={<IntlMessages id="appNotification.title" />}
                />
                <AppNotification />
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>

        <div className="ellipse-shape" />
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);
