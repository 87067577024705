import create from 'zustand';
import { persist } from 'zustand/middleware';
import { USER_TYPE } from './config';

const useUserStore = create(
  persist(
    set => ({
      type: '',
      setType: type => set(() => ({ type })),
    }),
    {
      name: USER_TYPE,
    },
  ),
);

export default useUserStore;
