import { createSlice } from '@reduxjs/toolkit';
import { FIXED_DRAWER, INSIDE_THE_HEADER, VERTICAL_NAVIGATION } from 'constants/ActionTypes';
import { DARK_BLUE } from 'constants/ThemeColors';

const rltLocale = ['ar'];

const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  themeColor: DARK_BLUE,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettings,
  reducers: {
    toggleCollapsedNav(state) {
      state.navCollapsed = !state.navCollapsed;
      return state;
    },
    setDrawerType(state, action) {
      state.drawerType = action.payload;
      return state;
    },
    updateWindowWidth(state, action) {
      state.width = action.payload;
      return state;
    },
    setThemeColor(state, action) {
      state.themeColor = action.payload;
      return state;
    },
    setDarkTheme(state) {
      state.darkTheme = !state.darkTheme;
      return state;
    },
    changeDirection(state) {
      state.isDirectionRTL = !state.isDirectionRTL;
      return state;
    },
    changeNavigationStyle(state, action) {
      state.navigationStyle = action.payload;
      return state;
    },
    setHorizontalMenuPosition(state, action) {
      state.horizontalNavPosition = action.payload;
      return state;
    },
    switchLanguage(state, action) {
      state.locale = action.payload;
      state.isDirectionRTL = rltLocale.includes(action.payload.locale);
      return state;
    },
  },
});

const { actions, reducer } = settingsSlice;

export const {
  toggleCollapsedNav,
  setDrawerType,
  updateWindowWidth,
  setThemeColor,
  setDarkTheme,
  changeDirection,
  changeNavigationStyle,
  setHorizontalMenuPosition,
  switchLanguage,
} = actions;

export default reducer;
