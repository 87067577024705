import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  data: {},
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: INIT_STATE,
  reducers: {
    setProfile(state, action) {
      state.data = action.payload;
      return state;
    },
  },
});

const { actions, reducer } = profileSlice;

export const { setProfile } = actions;

export default reducer;
