/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useGetOtp, useChangePassword } from 'api/hooks/auth.hooks';
import { toast } from 'react-toastify';
import logo from '../assets/images/logo.svg';

const RenderForm = ({
  form,
  setEmail,
  email,
  otp,
  setOtp,
  setPass,
  setConfPass,
  handleOtp,
  changePassowrd,
  isLoading,
  setForm,
}) => {
  switch (form) {
    case 1:
      return (
        <div className="app-login-form">
          <form>
            <fieldset>
              <TextField
                label={<IntlMessages id="appModule.email" />}
                fullWidth
                onChange={event => setEmail(event.target.value)}
                defaultValue={email}
                margin="normal"
                className="mt-1 my-sm-3 mr-4"
                style={{ width: '20rem', paddingBottom: '3rem' }}
              />
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <Button
                  onClick={handleOtp}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  <IntlMessages id="forgotPassword.otp" />
                </Button>

                <Link to="/signin">
                  <IntlMessages id="forgotPassword.signin" />
                </Link>
              </div>
            </fieldset>
          </form>
        </div>
      );
    case 2:
      return (
        <div className="app-login-form">
          <form>
            <fieldset>
              <TextField
                label={<IntlMessages id="appModule.otp" />}
                fullWidth
                onChange={event => setOtp(event.target.value)}
                value={otp}
                margin="normal"
                className="mt-1 my-sm-3 mr-4"
              />
              <TextField
                label={<IntlMessages id="forgotPassword.pass" />}
                fullWidth
                onChange={event => setPass(event.target.value)}
                margin="normal"
                className="mt-1 my-sm-3 mr-4"
              />
              <TextField
                label={<IntlMessages id="forgotPassword.confPass" />}
                fullWidth
                onChange={event => setConfPass(event.target.value)}
                margin="normal"
                className="mt-1 my-sm-3 mr-4"
              />
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <Button
                  onClick={changePassowrd}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  <IntlMessages id="forgotPassword.savePass" />
                </Button>

                <Link to="#" onClick={() => setForm(1)}>
                  <IntlMessages id="forgotPassword.changemail" />
                </Link>
              </div>
            </fieldset>
          </form>
        </div>
      );
    default:
      return <></>;
  }
};

const SignIn = props => {
  const [email, setEmail] = useState('admin@int-matrimony.com');
  const [otp, setOtp] = useState('');
  const [pass, setPass] = useState('');
  const [confPass, setConfPass] = useState('');
  const { authUser } = useSelector(({ auth }) => auth);
  const [form, setForm] = useState(1);

  const { mutateAsync: getOtp, isLoading: loading1 } = useGetOtp();
  const { mutateAsync: changePass, isLoading: loading2 } = useChangePassword();

  const isLoading = loading1 || loading2;

  useEffect(() => {
    if (authUser !== null) {
      props.history.push('/app/dashboard');
    }
  }, [authUser]);

  const handleOtp = async () => {
    setForm(2);
    await getOtp({ email });
  };

  const changePassowrd = () => {
    if (pass !== confPass) {
      toast.error('Password and Confirm Password must be same');
    } else {
      const obj = {
        otp,
        password: pass,
        confirmPassword: confPass,
      };
      changePass(obj);
    }
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Jambo">
            <img src={logo} alt="jambo" title="jambo" />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="forgotPassword.forgotPassword" />
            </h1>
          </div>
          <RenderForm
            {...{
              form,
              email,
              setEmail,
              setOtp,
              otp,
              setPass,
              setConfPass,
              isLoading,
              changePassowrd,
              setForm,
              handleOtp,
            }}
          />
        </div>
      </div>
      {isLoading && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default SignIn;
