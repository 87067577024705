import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import store, { history } from './store';
import './firebase/firebase';
import AppContainer from './containers/App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const persistor = persistStore(store);

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={AppContainer} />
          </Switch>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
    {/* <ReactQueryDevtools /> */}
    <ToastContainer />
  </QueryClientProvider>
);

export default App;
