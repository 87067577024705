import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  authUser: JSON.parse(localStorage.getItem('user')),
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INIT_STATE,
  reducers: {
    signIn(state, action) {
      state.authUser = action.payload;
      return state;
    },
    signOut(state) {
      state = INIT_STATE;
      return state;
    },
  },
});

const { actions, reducer } = authSlice;

export const { signIn, signOut } = actions;

export default reducer;
