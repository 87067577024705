/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAdminDetails, useAdminLogin } from 'api/hooks/auth.hooks';
import useUserStore from 'store/store';
import logo from '../assets/images/logo.svg';

const SignIn = props => {
  const setType = useUserStore(state => state.setType);
  const [email, setEmail] = useState('admin@int-matrimony.com');
  const [password, setPassword] = useState('admin@123');
  const { authUser } = useSelector(({ auth }) => auth);

  const { mutateAsync: adminLogin, isLoading: isLoadingLogin } = useAdminLogin();
  const { mutateAsync: adminDetails, isLoading: isLoadingDetails } = useAdminDetails();

  const isLoading = isLoadingLogin || isLoadingDetails;

  useEffect(() => {
    if (authUser) {
      props.history.push('/app/dashboard');
    }
  }, [authUser]);

  const handleSignIn = async e => {
    e.preventDefault();
    const data = await adminLogin({ email, password });
    await adminDetails();
    setType(data.user.type);
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Jambo">
            <img src={logo} alt="jambo" title="jambo" />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="appModule.email" />
            </h1>
          </div>

          <div className="app-login-form">
            <form onSubmit={handleSignIn}>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  onChange={event => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={event => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                    <IntlMessages id="appModule.signIn" />
                  </Button>

                  <Link to="/forgot-password">
                    <IntlMessages id="signIn.forgotPassword" />
                  </Link>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default SignIn;
