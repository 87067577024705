/**
 * Generate HTTP headers
 * @param {Object} headers
 */
const getHeader = async (headers = new Headers(), hasFiles = false) => {
  const defaultHeaders = new Headers();
  defaultHeaders.append('Content-Type', 'application/json');

  const token = localStorage.getItem('token');

  if (token) defaultHeaders.append('Authorization', `Bearer ${token}`);

  if (headers) {
    headers.forEach((value, key) => defaultHeaders.append(key, value));
  }

  if (hasFiles) {
    defaultHeaders.delete('Content-Type');
  }

  return defaultHeaders;
};

/**
 * Generate HTTP body
 * @param {Object} body
 * @param {Boolean} hasFiles
 */
const getBody = (body = {}, hasFiles = false) => {
  if (hasFiles) {
    return body;
  }

  return JSON.stringify(body);
};

export class ApiResponseError extends Error {
  constructor(message, code) {
    super(message || 'Oops! Something went wrong');
    this.name = 'ApiResponseError';
    this.code = code || 400;
  }
}

/**
 * Handle HTTP error
 * @param {Number} httpStatusCode
 * @param {Object | Error} response
 */
// const handleError = (httpStatusCode, response) => {
//   if (httpStatusCode === '401') {
//     localStorage.clear('token');
//     localStorage.clear('token');
//     window.location.replace('/signin');
//   }

//   if (!/^(2|3)[0-9][0-9]$/.test(String(httpStatusCode))) {
//     throw new ApiResponseError(response?.message, response?.code);
//   }
// };

const handleError = (httpStatusCode, response = {}) => {
  if (httpStatusCode === 401 || httpStatusCode === 403) {
    if (window.location.pathname !== '/signin') {
      localStorage.clear();
      window.location.href = '/signin';

      throw response;
    }
  }

  if (httpStatusCode < 200 || httpStatusCode > 302 || httpStatusCode === 429) {
    throw response;
  }
};

/**
 * Generate Request URL
 * @param {String} url
 * @param {Object} options
 * @param {String | undefined | null} options.baseURL
 * @param {boolean} options.isMockedURL
 */
const getURL = (url = '', options = { baseURL: null, isMockedURL: false }) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const finalURL = (options?.baseURL ?? baseURL) + url;

  return finalURL;
};

const defaultOptions = {
  baseURL: null,
  isMockedURL: false,
  headers: null,
  hasFiles: false,
};

/**
 * HTTP GET Request
 * @method GET
 * @param {String} url
 * @param {Object | undefined} options
 */
const fetchGet = async (url, options = defaultOptions) => {
  const result = await fetch(
    getURL(url, { isMockedURL: options?.isMockedURL, baseURL: options?.baseURL }),
    {
      method: 'GET',
      headers: await getHeader(options?.headers),
    },
  );

  const response = await result.json();
  handleError(result.status, response);
  return response;
};

/**
 * HTTP POST Request
 * @method POST
 * @param {String} url
 * @param {Object | undefined} options
 */
const fetchPost = async (url, body = {}, options = defaultOptions) => {
  const result = await fetch(
    getURL(url, { isMockedURL: options?.isMockedURL, baseURL: options?.baseURL }),
    {
      method: 'POST',
      headers: await getHeader(options?.headers, options?.hasFiles),
      body: getBody(body, options?.hasFiles),
    },
  );

  const response = await result.json();
  handleError(result.status, response);
  return response;
};

/**
 * HTTP PATCH Request
 * @method PATCH
 * @param {String} url
 * @param {Object} options
 */
const fetchPatch = async (url, body = {}, options = defaultOptions) => {
  const result = await fetch(
    getURL(url, { isMockedURL: options?.isMockedURL, baseURL: options?.baseURL }),
    {
      method: 'PATCH',
      headers: await getHeader(options?.headers, options?.hasFiles),
      body: getBody(body, options?.hasFiles),
    },
  );

  const response = await result.json();
  handleError(result.status, response);
  return response;
};

/**
 * HTTP PUT Request
 * @method PUT
 * @param {String} url
 * @param {Object} options
 */
const fetchPut = async (url, body = {}, options = defaultOptions) => {
  const result = await fetch(
    getURL(url, { isMockedURL: options?.isMockedURL, baseURL: options?.baseURL }),
    {
      method: 'PUT',
      headers: await getHeader(options?.headers, options?.hasFiles),
      body: getBody(body, options?.hasFiles),
    },
  );

  const response = await result.json();
  handleError(result.status, response);
  return response;
};

/**
 * HTTP DELETE Request
 * @method DELETE
 * @param {String} url
 * @param {Object} options
 */
const fetchDelete = async (url, body = {}, options = defaultOptions) => {
  const result = await fetch(
    getURL(url, { isMockedURL: options?.isMockedURL, baseURL: options?.baseURL }),
    {
      method: 'DELETE',
      headers: await getHeader(options?.headers, options?.hasFiles),
      body: getBody(body, options?.hasFiles),
    },
  );

  const response = await result.json();
  handleError(result.status, response);
  return response;
};

const http = {
  get: fetchGet,
  post: fetchPost,
  put: fetchPut,
  patch: fetchPatch,
  delete: fetchDelete,
};

export default http;
