import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // defaults to loca
import settings from 'reducers/Settings';
import auth from 'reducers/Auth';
import profile from 'reducers/userProfile';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  router: connectRouter(history),
  settings,
  auth,
  profile,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(routeMiddleware),
});

export default store;

export { history };
