/* eslint-disable import/prefer-default-export */
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  adminLogin,
  getAdminDetails,
  getOtp,
  resetPassowd,
  updateOwnPassword,
  updateProfile,
  verifyEmail,
} from 'api/requests/auth.requests';
import { signIn } from 'reducers/Auth';

export const useAdminLogin = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async data => {
      const res = await adminLogin(data);
      return res?.data;
    },
    {
      onSuccess: data => {
        localStorage.setItem('token', data?.tokens?.access?.token);
        localStorage.setItem('user', JSON.stringify(data?.user));
        toast.success('Welcome');
        queryClient.invalidateQueries();
        dispatch(signIn(data?.user));
      },
      onError: error => {
        toast.error(error?.message);
      },
    },
  );
};

export const useAdminDetails = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async () => {
      const res = await getAdminDetails();
      return res?.data;
    },
    {
      onSuccess: data => {
        const user = data?.user;
        if (user) {
          user.profileImage = data?.userProfiles?.userProfileDraft?.profileImage;
          localStorage.setItem('user', JSON.stringify(data?.user));
          queryClient.invalidateQueries();
          dispatch(signIn(data?.user));
        }
      },
      onError: error => {
        toast.error(error?.message || 'Unable to fetch user details');
      },
    },
  );
};

export const useGetOtp = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async data => {
      const res = await getOtp(data);
      return res;
    },
    {
      onSuccess: data => {
        toast.success(data.message);
        queryClient.invalidateQueries();
      },
      onError: error => {
        toast.error(error?.message);
      },
    },
  );
};

export const useChangePassword = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async data => {
      const res = await resetPassowd(data);
      return res;
    },
    {
      onSuccess: data => {
        toast.success(data.message);
        queryClient.invalidateQueries();
        window.location.replace('/signin');
      },
      onError: error => {
        toast.error(error?.message);
      },
    },
  );
};

export const useVerifyEmail = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async ({ site, data }) => {
      const res = await verifyEmail(site, data);
      return res;
    },
    {
      onSuccess: data => {
        localStorage.setItem('token', data?.data?.tokens?.access?.token);
        localStorage.setItem('user', JSON.stringify(data?.data?.user));
        toast.success('Welcome');
        queryClient.invalidateQueries();
        dispatch(signIn(data?.data?.user));
      },
      onError: error => {
        toast.error(error?.message);
      },
    },
  );
};

export const useChangeOwnPassword = () =>
  useMutation(
    async data => {
      const res = await updateOwnPassword(data);
      return res;
    },
    {
      onSuccess: () => {
        toast.success('Password changed successfully');
      },
      onError: error => {
        toast.error(error?.message);
      },
    },
  );

export const useUpdateProfile = () =>
  useMutation(
    async data => {
      const res = await updateProfile(data);
      return res;
    },
    {
      onSuccess: () => {
        toast.success('Profile updated successfully');
      },
      onError: error => {
        toast.error(error?.message);
      },
    },
  );
