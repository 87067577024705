import http from '../http';

export const adminLogin = data => http.post('/matrimony/auth/login/email', data);

export const getAdminDetails = () => http.get('/matrimony/auth/me');

export const getOtp = data => http.post('/matrimony/auth/reset-password/email/request-otp', data);

export const resetPassowd = data =>
  http.post('/matrimony/auth/reset-password/email/reset-with-otp', data);

export const fetchAdmin = () => http.get('/admin');

export const verifyEmail = (site, data) => http.post(`/${site}/auth/verify-email?token=${data}`);

export const updateOwnPassword = data =>
  http.post('/master/master-account/update-own-password', data);

export const updateProfile = data => http.post('/master/master-account/update-own-profile', data);
