import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';

const Routes = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/dashboard`}
      component={asyncComponent(() => import('./Dashboard'))}
    />
    <Route
      path={`${match.url}/profile`}
      component={asyncComponent(() => import('./AdminProfile'))}
    />
    <Route
      path={`${match.url}/masters/blood-group`}
      component={asyncComponent(() => import('./masters/bloodGroup'))}
    />
    <Route
      path={`${match.url}/masters/report-reason`}
      component={asyncComponent(() => import('./masters/reportReason'))}
    />
    <Route
      path={`${match.url}/masters/caste`}
      component={asyncComponent(() => import('./masters/caste'))}
    />
    <Route
      path={`${match.url}/masters/college`}
      component={asyncComponent(() => import('./masters/college'))}
    />
    <Route
      path={`${match.url}/masters/company`}
      component={asyncComponent(() => import('./masters/company'))}
    />
    <Route
      path={`${match.url}/masters/complexion`}
      component={asyncComponent(() => import('./masters/complexion'))}
    />
    <Route
      path={`${match.url}/notification`}
      component={asyncComponent(() => import('./notification/notification'))}
    />
    <Route
      path={`${match.url}/masters/designation`}
      component={asyncComponent(() => import('./masters/designation'))}
    />
    <Route
      path={`${match.url}/masters/education`}
      component={asyncComponent(() => import('./masters/education'))}
    />
    <Route
      path={`${match.url}/masters/employment-status`}
      component={asyncComponent(() => import('./masters/employmentStatus'))}
    />
    <Route
      path={`${match.url}/masters/event-type`}
      component={asyncComponent(() => import('./masters/eventType'))}
    />
    <Route
      path={`${match.url}/masters/family-affluence`}
      component={asyncComponent(() => import('./masters/familyAffluence'))}
    />
    <Route
      path={`${match.url}/masters/family-type`}
      component={asyncComponent(() => import('./masters/familyType'))}
    />
    <Route
      path={`${match.url}/masters/family-value`}
      component={asyncComponent(() => import('./masters/familyValue'))}
    />
    <Route
      path={`${match.url}/masters/favorite-sports`}
      component={asyncComponent(() => import('./masters/favoriteSports'))}
    />
    <Route
      path={`${match.url}/masters/favorite-music`}
      component={asyncComponent(() => import('./masters/favoriteMusic'))}
    />
    <Route
      path={`${match.url}/masters/food-habits`}
      component={asyncComponent(() => import('./masters/foodHabits'))}
    />
    <Route
      path={`${match.url}/masters/gender`}
      component={asyncComponent(() => import('./masters/gender'))}
    />
    <Route
      path={`${match.url}/masters/health-info`}
      component={asyncComponent(() => import('./masters/healthInfo'))}
    />
    <Route
      path={`${match.url}/masters/indentity-type`}
      component={asyncComponent(() => import('./masters/identityType'))}
    />
    <Route
      path={`${match.url}/masters/marital-status`}
      component={asyncComponent(() => import('./masters/maritalStatus'))}
    />
    <Route
      path={`${match.url}/masters/native-place`}
      component={asyncComponent(() => import('./masters/nativePlace'))}
    />
    <Route
      path={`${match.url}/masters/occupation`}
      component={asyncComponent(() => import('./masters/occupation'))}
    />
    <Route
      path={`${match.url}/masters/professional-area`}
      component={asyncComponent(() => import('./masters/professionalArea'))}
    />
    <Route
      path={`${match.url}/masters/tobacco`}
      component={asyncComponent(() => import('./masters/tobacco'))}
    />
    <Route
      path={`${match.url}/masters/country`}
      component={asyncComponent(() => import('./masters/country'))}
    />
    <Route
      path={`${match.url}/masters/state`}
      component={asyncComponent(() => import('./masters/state'))}
    />
    <Route
      path={`${match.url}/masters/zip`}
      component={asyncComponent(() => import('./masters/zip'))}
    />
    <Route
      path={`${match.url}/masters/city`}
      component={asyncComponent(() => import('./masters/city'))}
    />
    <Route
      path={`${match.url}/masters/community`}
      exact
      component={asyncComponent(() => import('./masters/community'))}
    />
    <Route
      path={`${match.url}/masters/donation-purpose`}
      component={asyncComponent(() => import('./masters/donationPurpose'))}
    />
    <Route
      path={`${match.url}/masters/drinking`}
      component={asyncComponent(() => import('./masters/drinking'))}
    />
    <Route
      path={`${match.url}/masters/smoking`}
      component={asyncComponent(() => import('./masters/smoking'))}
    />
    <Route
      path={`${match.url}/masters/gotra`}
      component={asyncComponent(() => import('./masters/gotra'))}
    />
    <Route
      path={`${match.url}/masters/hobbies`}
      component={asyncComponent(() => import('./masters/hobbies'))}
    />
    <Route
      path={`${match.url}/masters/interest`}
      component={asyncComponent(() => import('./masters/interest'))}
    />
    <Route
      path={`${match.url}/masters/languages`}
      component={asyncComponent(() => import('./masters/languages'))}
    />
    <Route
      path={`${match.url}/masters/lifestyle-options`}
      component={asyncComponent(() => import('./masters/lifestyleOptions'))}
    />
    <Route
      path={`${match.url}/masters/medical-history`}
      component={asyncComponent(() => import('./masters/medicalHistory'))}
    />
    <Route
      path={`${match.url}/masters/relation`}
      component={asyncComponent(() => import('./masters/relation'))}
    />
    <Route
      path={`${match.url}/masters/religion`}
      component={asyncComponent(() => import('./masters/religion'))}
    />
    <Route
      path={`${match.url}/masters/sub-community`}
      component={asyncComponent(() => import('./masters/subCommunity'))}
    />
    {/* <Route
      path={`${match.url}/matrimony-user`}
      exact
      component={asyncComponent(() => import('./users/matrimonyUsers'))}
    /> */}
    <Route
      path={`${match.url}/:site/all-users`}
      exact
      component={asyncComponent(() => import('./users/matrimonyUsers'))}
    />
    {/* <Route
      path={`${match.url}/matrimony-user/profile/:userId`}
      component={asyncComponent(() => import('./users/matrimonyUserProfile'))}
    /> */}

    <Route
      path={`${match.url}/:site/complained-users`}
      component={asyncComponent(() => import('./users/ComplainedUsers'))}
    />

    <Route
      path={`${match.url}/:site/reported-users`}
      component={asyncComponent(() => import('./users/ReportedUsers'))}
    />

    <Route
      path={`${match.url}/:site/all-users/profile/:userId`}
      component={asyncComponent(() => import('./users/matrimonyUserProfile'))}
    />
    <Route
      path={`${match.url}/create-user-group`}
      component={asyncComponent(() => import('./users/userGroup'))}
    />
    <Route
      path={`${match.url}/:site/my-verification/:userId`}
      component={asyncComponent(() => import('./users/myVerification/profile'))}
    />
    <Route
      path={`${match.url}/:site/my-verification`}
      component={asyncComponent(() => import('./users/myVerification/index'))}
    />

    <Route
      path={`${match.url}/:site/ad-management`}
      component={asyncComponent(() => import('./community/AdManagement/AdManagement'))}
    />

    <Route
      path={`${match.url}/:site/feeds`}
      component={asyncComponent(() => import('./community/Feed'))}
    />

    <Route
      path={`${match.url}/:site/events`}
      component={asyncComponent(() => import('./community/Event'))}
    />

    <Route
      path={`${match.url}/:site/reported-posts`}
      component={asyncComponent(() => import('./community/ReportedPost/ReportedPosts'))}
    />

    {/* <Route
      path={`${match.url}/all-pending-verification/profile/:userId`}
      component={asyncComponent(() => import('./users/pendingProfile'))}
    /> */}
    <Route
      path={`${match.url}/:site/all-pending-verification/profile/:userId`}
      component={asyncComponent(() => import('./users/pendingProfile'))}
    />
    {/* <Route
      path={`${match.url}/all-pending-verification`}
      component={asyncComponent(() => import('./users/allPendingVerification'))}
    /> */}
    <Route
      path={`${match.url}/:site/all-pending-verification`}
      component={asyncComponent(() => import('./users/allPendingVerification'))}
    />
    <Route path={`${match.url}/staff`} component={asyncComponent(() => import('./users/staff'))} />
    {/* <Route
      path={`${match.url}/banned-user`}
      exact
      component={asyncComponent(() => import('./users/bannerUser'))}
    /> */}
    <Route
      path={`${match.url}/:site/banned-user`}
      exact
      component={asyncComponent(() => import('./users/bannerUser'))}
    />
    <Route
      path={`${match.url}/:site/deactivated-user`}
      exact
      component={asyncComponent(() => import('./users/deactivatedUser'))}
    />
    {/* <Route
      path={`${match.url}/banned-user/profile`}
      component={asyncComponent(() => import('./users/bannedUserProfile'))}
    /> */}
    <Route
      path={`${match.url}/:site/banned-user/profile/:userId`}
      component={asyncComponent(() => import('./users/bannedUserProfile'))}
    />
    <Route
      path={`${match.url}/:site/deactivated-user/profile/:userId`}
      component={asyncComponent(() => import('./users/deactivatedUserProfile'))}
    />
    <Route
      path={`${match.url}/success-stories`}
      component={asyncComponent(() => import('./cms/successStories'))}
    />
    <Route path={`${match.url}/jobs`} component={asyncComponent(() => import('./cms/jobs'))} />
    <Route
      path={`${match.url}/matrimony/faq`}
      component={asyncComponent(() => import('./cms/faq'))}
    />
    <Route
      path={`${match.url}/community/faq`}
      component={asyncComponent(() => import('./cms/faq'))}
    />
    <Route
      path={`${match.url}/terms-condition`}
      component={asyncComponent(() => import('./cms/terms&Cond'))}
    />
    <Route
      path={`${match.url}/community/how-to-use`}
      component={asyncComponent(() => import('./cms/htu'))}
    />
    <Route
      path={`${match.url}/matrimony/how-to-use`}
      component={asyncComponent(() => import('./cms/htu'))}
    />
    <Route
      path={`${match.url}/privacy-policy`}
      component={asyncComponent(() => import('./cms/privPolicy'))}
    />
    <Route path={`${match.url}/jobs`} component={asyncComponent(() => import('./cms/jobs'))} />
    <Route
      path={`${match.url}/community/about`}
      component={asyncComponent(() => import('./cms/about'))}
    />
    <Route
      path={`${match.url}/more-tabs`}
      component={asyncComponent(() => import('./cms/moreTabs'))}
    />
    <Route
      path={`${match.url}/matrimony/about`}
      component={asyncComponent(() => import('./cms/about'))}
    />
    <Route
      path={`${match.url}/matrimony/social-link`}
      component={asyncComponent(() => import('./cms/socialLink'))}
    />
    <Route
      path={`${match.url}/community/social-link`}
      component={asyncComponent(() => import('./cms/socialLink'))}
    />
    <Route
      path={`${match.url}/community/vision-mission`}
      component={asyncComponent(() => import('./cms/visionMission'))}
    />
    <Route
      path={`${match.url}/matrimony/vision-mission`}
      component={asyncComponent(() => import('./cms/visionMission'))}
    />
    <Route
      path={`${match.url}/disclaimer`}
      component={asyncComponent(() => import('./cms/disclaimer'))}
    />
    <Route
      path={`${match.url}/products/:id/plans`}
      component={asyncComponent(() => import('./plans/plan'))}
    />
    <Route
      path={`${match.url}/:site/products/:id/plans`}
      component={asyncComponent(() => import('./plans/plan'))}
    />
    {/* <Route
      path={`${match.url}/products`}
      component={asyncComponent(() => import('./products/product'))}
    /> */}
    <Route
      path={`${match.url}/:site/products`}
      component={asyncComponent(() => import('./products/product'))}
    />
    {/* <Route
      path={`${match.url}/coupons`}
      component={asyncComponent(() => import('./coupons/coupons'))}
    /> */}
    <Route
      path={`${match.url}/:site/coupons`}
      component={asyncComponent(() => import('./coupons/coupons'))}
    />
    {/* <Route
      path={`${match.url}/subscription`}
      component={asyncComponent(() => import('./subscription/subscription'))}
    /> */}
    <Route
      path={`${match.url}/:site/all-subscription`}
      component={asyncComponent(() => import('./subscription/subscription'))}
    />
    <Route
      path={`${match.url}/:site/cancel-subscription`}
      component={asyncComponent(() => import('./cancel-subscription/CancelSubscriptions'))}
    />
    <Route
      path={`${match.url}/complaint`}
      component={asyncComponent(() => import('./complaint/complaint'))}
    />
    <Route
      path={`${match.url}/contact`}
      component={asyncComponent(() => import('./contact/contact'))}
    />
    <Route
      path={`${match.url}/job-application`}
      component={asyncComponent(() => import('./jobApplication/jobApplication'))}
    />
    <Route
      path={`${match.url}/donation`}
      component={asyncComponent(() => import('./donation/donation'))}
    />
    <Route
      path={`${match.url}/platform-audit`}
      component={asyncComponent(() => import('./platformAudit/platformAudit'))}
    />
    <Route
      path={`${match.url}/notification-center`}
      component={asyncComponent(() => import('./notificationCenter/notificationCenter'))}
    />
    <Route
      path={`${match.url}/platform-settings`}
      component={asyncComponent(() => import('./platformSetting/platformSetting'))}
    />
    {/* <Route
      path={`${match.url}/platform-revenue`}
      component={asyncComponent(() => import('./platformRevenue/platformRevenue'))}
    /> */}
    <Route
      path={`${match.url}/:site/revenue`}
      component={asyncComponent(() => import('./platformRevenue/platformRevenue'))}
    />
  </Switch>
);

export default withRouter(Routes);
