/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CircularProgress, Button, Grid } from '@material-ui/core';
import { useVerifyEmail } from 'api/hooks/auth.hooks';

const EmailVerification = props => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { mutateAsync, isLoading: isVerificationLoading } = useVerifyEmail();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');

  const verifyEmail = async () => {
    if (token) {
      try {
        await mutateAsync({ site: 'matrimony', data: token });
        props.history.push('/app/dashboard');
      } catch (err) {
        setIsInvalid(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (token) {
      verifyEmail();
    }
  }, [token]);

  useEffect(() => {
    if (!token) {
      setIsLoading(false);
    }
  }, [token]);

  return (
    <Grid container spacing={5}>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        style={{ height: '80vh' }}
      >
        <h4>
          {isLoading || isVerificationLoading ? (
            <CircularProgress color="primary" />
          ) : token ? (
            isInvalid ? (
              <>
                <p className="h3 text-center text-muted mt-4">Invalid or expired token</p>
                <Link to="/app/dashboard" className="text-decoration-none">
                  <Button
                    variant="contained"
                    color="primary"
                    className="font-weight-bold font-size-md px-4 py-3 mt-2"
                  >
                    Go to Home page
                  </Button>
                </Link>
              </>
            ) : (
              ''
            )
          ) : (
            <>
              <p className="h3  text-center text-muted mt-4">Invalid URL</p>
              <Link to="/app/dashboard" className="text-decoration-none">
                <Button
                  variant="contained"
                  color="primary"
                  className="font-weight-bold font-size-md px-4 py-3 mt-2"
                >
                  Go to Home page
                </Button>
              </Link>
            </>
          )}
        </h4>
      </Grid>
    </Grid>
  );
};

export default EmailVerification;
